import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, IconButton, Typography } from "@mui/material";

import ContextUserInfo from "../../context/UserInformation";
import EachSaveMovie from "./EachSaveMovie";
import { getUser } from "../../Services/Connection/ApiEndpoints/authentication";
import BookMardLoading from "../Loading/BookMardLoading";
import {getWithExpiry} from "../../Helper";

const navbarHeight = 60;

const SavedMovies = () => {
  // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
  const  userInfo = getWithExpiry('user');
  const [isLoading, setIsLoading] = useState(false);
  const [savedMovies, setSavedMovies] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
      !userInfo && navigate("/loading");

      if (userInfo?.bookmarks?.media) {
          setSavedMovies(userInfo.bookmarks.media);
      } else {
          navigate("/");
      }
    // const fetchUserInfo = async () => {
    //   const { data } = await getUser();
    //   if (data) {
    //     setUserInfo(data);
    //
    //     if (data?.bookmarks?.media) {
    //       setSavedMovies(data.bookmarks.media);
    //     } else {
    //       navigate("/");
    //     }
    //   }
    //   setIsLoading(false);
    // };
    //
    // fetchUserInfo().catch(console.error);
  }, []);

  return isLoading ? (
    <BookMardLoading />
  ) : (
    <>
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "center",
          // flexWrap: "wrap",
          height: "100%",
          width: "100%",
          py: 2,
          pt: "70px",
        }}
      >
        <Grid container spacing={2} sx={{ px: 2 }}>
          {savedMovies ? (
            savedMovies.map(
              ({ id, image, persian_name, imdb_score, badges }) => (
                <Grid key={id} item xs={6}>
                  <EachSaveMovie
                    id={id}
                    image={image}
                    persian_name={persian_name}
                    imdb_score={imdb_score}
                    badges={badges}
                  />
                </Grid>
              )
            )
          ) : (
            <Box
              sx={{
                width: "100vw",
                height: "100vh",
                pt: "100px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>هیچ فیلم یا سریالی در ذخیره های شما نیست!</Typography>
            </Box>
          )}
        </Grid>
      </Box>
      {/* navbar */}
      <Box
        sx={{
          width: "100%",
          height: "50px",
          position: "fixed",
          top: 15,
          zIndex: 100,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            background: "rgba( 255, 255, 255, 0.25 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 4,
            mx: 2,
          }}
        >
          <ArrowForwardIosIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Box>
    </>
  );
};

export default SavedMovies;
