import React from "react";
import { Box, Typography } from "@mui/material";
import MovieWatch from "../../MovieInfoPart/components/MovieWatch";

const MovieBanner = ({ image, title, hasSeasons, files }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {/* button of links (movie stream) */}

      {!hasSeasons && (
        <MovieWatch hasSeasons={hasSeasons} files={files} image={image} />
      )}

      <img
        src={image}
        alt={title}
        style={{ width: "100%", height: "30vh", objectFit: "cover" }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "absolute",
          bottom: "-1px",
          display: "block",
          width: "100%",
          height: "80px",
          backgroundImage:
            "linear-gradient(to bottom, rgba(0,0,0,0), rgba(10,25,40,1) 100%)",
        }}
      >
        <Typography
          className="movieTitle"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "end",
            px: 2,
            mb: 2,
            color: "primary",
            fontSize: 24,
            fontFamily: "YekanBakhFat",
            textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            bottom: 4,
            width: "100%",
            height: "5px",
            background: "#0a1928",
            mt: 1,
            filter: "blur(4px)",
          }}
        />
      </Box>
    </Box>
  );
};

export default MovieBanner;
