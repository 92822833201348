import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {BsPlayFill} from "react-icons/bs";
import {FiShare2, FiDownload} from "react-icons/fi";
import {HiOutlineBookmark} from "react-icons/hi";
import {BsBookmarkFill} from "react-icons/bs";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import {Box, Button, IconButton, Typography} from "@mui/material";
import ContextUserInfo from "../../../../context/UserInformation";
import {saveMovie} from "../../../../Services/Connection/ApiEndpoints/movies";
import MovieWatch from "../../MovieInfoPart/components/MovieWatch";
import MovieDownload from "../../MovieInfoPart/components/MovieDownload";
import {getWithExpiry, setWithExpiry} from "../../../../Helper";

const MovieStream = ({movieData}) => {
    const navigate = useNavigate();
    // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
    const userInfo = getWithExpiry('user');
    const [saved, setSaved] = useState(false);

    const {id} = movieData?.media;

    useEffect(() => {
        if (userInfo?.bookmarks && userInfo?.bookmarks?.media) {
            userInfo?.bookmarks?.media.forEach((movie) => {
                movie?.id === id && setSaved(true);
            });
        }
    }, []);

    const handleSaveMovie = async () => {
        setSaved(!saved);
        try {
            const {data} = await saveMovie(id);

            if (data?.status === "success") {
                const newUserInfo = {...userInfo};

                if (data?.is_bookmarked) {
                    if (newUserInfo?.bookmarks?.media) {
                        newUserInfo.bookmarks.media.push(movieData);
                        localStorage.removeItem('user');
                        // setUserInfo(newUserInfo);
                        setSaved(true);
                    } else {
                        const media = [];
                        newUserInfo.bookmarks = {media};
                        newUserInfo.bookmarks.media.push(movieData);
                        // setUserInfo(newUserInfo);
                        localStorage.removeItem('user');
                        setSaved(true);
                    }
                } else {
                    const newSaves = newUserInfo?.bookmarks?.media.filter(
                        (savedMovie) => savedMovie?.media?.id !== id
                    );
                    newUserInfo.bookmarks.media = newSaves;
                    // setUserInfo(newUserInfo);
                    localStorage.removeItem('user');
                    setSaved(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
            }}
        >
            {/* button of links (movie stream) */}
            {/* <MovieWatch hasSeasons={movieData?.hasSeasons} files={movieData?.files} /> */}

            <Box sx={{ml: 1}}>
                <IconButton
                    onClick={handleSaveMovie}
                    sx={{border: "1px solid #fefe", borderRadius: "10px", p: 1, mr: 1}}
                >
                    {saved ? (
                        <BookmarkIcon sx={{fontSize: 24, color: "#fff"}}/>
                    ) : (
                        <BookmarkBorderIcon sx={{fontSize: 24, color: "#fff"}}/>
                    )}
                </IconButton>

                {!movieData?.hasSeasons && (
                    <MovieDownload
                        hasSeasons={movieData?.hasSeasons}
                        files={movieData?.files}
                    />
                )}

                <IconButton
                    sx={{
                        border: "1px solid #fefe",
                        borderRadius: "10px",
                        p: 1.25,
                        ml: 1,
                    }}
                >
                    <FiShare2 style={{fontSize: 20, color: "#fff"}}/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default MovieStream;
