import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";

const MovieLoading = () => {
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "30vh",
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={"100%"}
          sx={{
            fontSize: "1rem",
            background: "#444",
          }}
        />

        <Box
          sx={{
            width: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "absolute",
            top: 10,
            px: 2,
          }}
        >
          <Skeleton
            variant="rectangular"
            width={"40px"}
            height={"40px"}
            sx={{
              background: "#0a1928",
              bottom: 15,
              borderRadius: 3,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={"40px"}
            height={"40px"}
            sx={{
              background: "#0a1928",
              bottom: 15,
              borderRadius: 3,
            }}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={"20px"}
          sx={{
            ml: 2,
            background: "#0a1928",
            position: "absolute",
            bottom: 15,
            borderRadius: 3,
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2.5 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton
            variant="rectangular"
            width={"40px"}
            height={"10px"}
            sx={{
              background: "#444",
              bottom: 15,
              borderRadius: 3,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={"40px"}
            height={"10px"}
            sx={{
              background: "#444",
              bottom: 15,
              borderRadius: 3,
              mx: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={"40px"}
            height={"10px"}
            sx={{
              background: "#444",
              bottom: 15,
              borderRadius: 3,
            }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Skeleton
            variant="rectangular"
            width={"42px"}
            height={"42px"}
            sx={{
              background: "#444",
              bottom: 15,
              borderRadius: 3,
              mr: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={"42px"}
            height={"42px"}
            sx={{
              background: "#444",
              bottom: 15,
              borderRadius: 3,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
        <Skeleton
          variant="rectangular"
          width={"65%"}
          height={"80px"}
          sx={{
            background: "#444",
            bottom: 15,
            borderRadius: 3,
            mx: 2,
          }}
        />

        <Skeleton
          variant="rectangular"
          height={"80px"}
          sx={{
            background: "#444",
            bottom: 15,
            borderRadius: "16px 0px 0px 16px",
            display: "flex",
            flexGrow: 1,
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 4, px: 2 }}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"10px"}
          sx={{
            background: "#444",
            bottom: 15,
            borderRadius: 3,
          }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"10px"}
          sx={{
            background: "#444",
            bottom: 15,
            borderRadius: 3,
            mt: 1.5,
          }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"10px"}
          sx={{
            background: "#444",
            bottom: 15,
            borderRadius: 3,
            mt: 1.5,
          }}
        />
        <Skeleton
          variant="rectangular"
          width={"25%"}
          height={"20px"}
          sx={{
            background: "#444",
            bottom: 15,
            borderRadius: 3,
            mt: 1.5,
          }}
        />
      </Box>
    </Box>
  );
};

export default MovieLoading;
