import React, { useState, useEffect } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { BsPlayFill } from "react-icons/bs";
import SiresWatchModal from "../../MovieInfoPart/components/SiresWatchModal";

const EpisodeSelect = ({ files }) => {
  const [seasonsCount, setSeasonsCount] = useState(0);
  const [currentSeason, setCurrentSeason] = useState("1");
  const [currentEpisodesList, setCurrentEpisodesList] = useState("");
  const [currentEpisode, setCurrentEpisode] = useState("");
  const [episodeLink, setEpisodeLink] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // set seasons count
    const seasons = Object.keys(files);
    setSeasonsCount(seasons);
  }, []);

  useEffect(() => {
    // get current episodes of chosen season
    const episodes = Object.values(files[currentSeason]);
    setCurrentEpisodesList(episodes);
  }, [currentSeason]);

  return (
    <Box sx={{ px: 2, mb: 2, mt: 4 }}>
      {seasonsCount && (
        <TextField
          select
          fullWidth
          label="انتخاب فصل"
          value={currentSeason}
          onChange={(e) => setCurrentSeason(e.target.value)}
        >
          {seasonsCount.map((season, i) => (
            <MenuItem key={i} value={season}>
              {`فصل ${season}`}
            </MenuItem>
          ))}
        </TextField>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
        {currentEpisodesList &&
          currentEpisodesList.map((episode, i) => (
            <Box
              onClick={() => {
                setCurrentEpisode(episode);
                handleOpen();
              }}
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#102943",
                borderRadius: 2,
                px: 2,
                py: 1,
                mt: 0.5,
              }}
            >
              <BsPlayFill
                style={{
                  fontSize: 22,
                  color: "#fff",
                  marginLeft: "10px",
                }}
              />
              <Typography>{episode?.title}</Typography>
            </Box>
          ))}
      </Box>

      <SiresWatchModal
        open={open}
        currentEpisode={currentEpisode}
        onClose={handleClose}
        setEpisodeLink={setEpisodeLink}
      />
    </Box>
  );
};

export default EpisodeSelect;
