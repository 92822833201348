import http from "../httpService";
import { apiUrl } from "../config";

const apiEndpoint = apiUrl + "/auth";

const loginApi = apiEndpoint + "/login";
const verifyApi = apiEndpoint + "/verify";

const userInfo = apiUrl + "/user";

//login the user
export function loginReq(cellphone) {
  return http.post(loginApi, {
    cellphone,
  });
}

//login the  with hmvz
export function loginHmvz(type, email, password, cellphone) {
  return http.post(loginApi, {
    type,
    email,
    password,
    cellphone,
  });
}

//verify the otp for loging in
export function verifyOtpCodeReq(type, email, password, cellphone, code) {
  if (email) {
    return http.post(verifyApi, {
      type,
      email,
      password,
      cellphone,
      code,
    });
  } else {
    return http.post(verifyApi, {
      cellphone,
      code,
    });
  }
}

//verify the otp for signup
export function verifyOtpCodeReqSignUp(name, cellphone, email, code) {
  return http.post(verifyApi, {
    name,
    cellphone,
    email,
    code,
  });
}

//get the user information from token
export function getUser() {
  const token = localStorage.getItem("token");
  return http.get(userInfo, { headers: { Authorization: `Bearer ${token}` } });
}

//first time register
// export function signUpReq(name, cellphone, email) {
//   return http.get(apiEndpoint, {
//     name,
//     email,
//     cellphone,
//   });
// }
