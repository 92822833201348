import React, {useState} from "react";
import {ThemeProvider} from "@mui/material";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {toast} from "react-toastify";
import {darkTheme, lightTheme} from "./theme";
import {RTL} from "./Packages/muiRtl/index";
import ProjectRoutes from "./ProjectRoutes";

function App() {
    const [themeMode, setThemeMode] = useState("dark");
    const [open, setOpen] = useState(false);
    return (
        <RTL>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
                <ProjectRoutes/>
            </ThemeProvider>
        </RTL>
    );
}

export default App;
