import React, {useState} from "react";
import {toast} from "react-toastify";
import {BsPlayFill} from "react-icons/bs";
import {FiDownload} from "react-icons/fi";
import {FaCrown} from "react-icons/fa";
import {Box, IconButton, Modal, Typography} from "@mui/material";
import {getMovieLink} from "../../../../Services/Connection/ApiEndpoints/movies";
import {useNavigate} from "react-router-dom";

const style = {
  position: "absolute",
  bottom: "0",
  width: "100%",
  overflowY: "scroll",
  background: "rgba( 11,25,40, 0.3 )",
  backdropFilter: "blur(3px)",
  WebkitBackdropFilter: "blur(3px)",
  borderRadius: 5,
};

const MovieWatch = ({ hasSeasons, files, image }) => {
    const navigate = useNavigate();
    const isAndroid = window.navigator.userAgent.match(/Android/i);

  const [open, setOpen] = useState(false);
  const [movieLink, setMovieLink] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGetLink = async (id, title) => {
    // setCurrentEpisode(title);

    try {
      const { data } = await getMovieLink(id);

      if (data?.status === "success") {
          // console.log(isAndroid);
        setMovieLink(data?.url);
          // if(isAndroid){
          //     // window.location.href = `intent:${data?.url}#Intent;type=video/*;end;`;
          //     const intentUrl = `intent://play/#Intent;scheme=http;type=video/*;action=android.intent.action.VIEW;end;`
          //     window.location.href = intentUrl + encodeURIComponent(data?.url);
          //     // window.open(data?.url)
          // }else{
          //     navigate(`/moviePlayer/${id}`, { state: { movieLink: data?.url } });
          // }
          navigate(`/moviePlayer/${id}`, { state: { movieLink: data?.url } });

      }
    } catch (error) {
      if (error?.response.status === 403) {
        navigate("/plans/purchace");

        toast.error(error?.response?.data?.errors);
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          position: "absolute",
          top: "40%",
          right: "40%",
          background: "rgba( 11,25,40, 0.7 )",
          boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
          borderRadius: 5,
          mx: 2,
        }}
      >
        <BsPlayFill style={{ color: "#fff", fontSize: 40 }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ px: 2, maxHeight: "85vh" }}>
            {/* inside of modal */}

            {!hasSeasons && (
              <Box sx={{ py: 2 }}>
                {files &&
                  files.map(({ id, quality, title, type, is_free }) => (
                    <Box
                      key={id}
                      onClick={() => handleGetLink(id, title)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        mt: 0.5,
                        px: 2,
                        py: 1,
                        position: "relative",
                        background:
                          type === "original"
                            ? "#ff5800"
                            : type === "dubbed"
                            ? "#2dba5e"
                            : type === "subtitle"
                            ? "#00b9d0"
                            : null,
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          pt: 0.5,
                          pr: 0.5,
                          fontSize: 12,
                        }}
                      >
                        {title}
                      </Typography>
                      <FaCrown
                        style={{
                          fontSize: 19,
                          color: "#ffd444",
                          marginRight: "5px",
                          paddingLeft: "2px",
                          visibility: is_free ? "hidden" : "visible",
                        }}
                      />
                      <FiDownload
                        style={{
                          fontSize: 20,
                          color: "#fefe",
                          marginRight: "5px",
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MovieWatch;
