import React from "react";
import { Box, Typography } from "@mui/material";

const MovieBadges = ({ badges }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {badges &&
        badges.map((badge, i) => (
          <Typography
            key={i}
            sx={{
              fontSize: 11,
              mr: 1,
              mt: 1,
              px: 1,
              py: 0.5,
              borderRadius: "10px",
              background: "#3f51b5",
              color: "#fefefe",
            }}
          >
            {badge}
          </Typography>
        ))}
    </Box>
  );
};

export default MovieBadges;
