import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Button, Typography } from "@mui/material";
import { getUser } from "../../../Services/Connection/ApiEndpoints/authentication";

const PlanBanner = () => {
  const [bannerShow, setBannerShow] = useState(true);

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: bannerShow ? "flex" : "none",
        backgroundImage: "url(/images/bannerPr.png)",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 50%",

        borderRadius: "10px",
        overflow: "hidden",
        mb: 2,
        mx: 1,
        px: 3,
        py: 2,
      }}
    >
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          pr: 2,
        }}
      >
        <Typography sx={{ fontFamily: "YekanBakhFat", fontSize: 16, mb: 1 }}>
          برای تماشای فیلم و سریال های موردعلاقه خود بدون تبلیغات، کلیک کنید.
        </Typography>
        <Button
          onClick={() => navigate("/plans/purchace")}
          sx={{
            width: "fit-content",
            fontSize: 12,
            px: 4,
            color: "#fefe",

            background: "rgba( 255, 255, 255, 0.25 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 3,
          }}
        >
          خرید اشتراک
        </Button>
      </Box>
      <Box sx={{ width: "10%" }}>
        <IconButton
          onClick={() => setBannerShow(false)}
          sx={{
            background: "rgba( 255, 255, 255, 0.25 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 3,
          }}
        >
          <CloseIcon color="primary" sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PlanBanner;
