import React, { useContext} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import {setWithExpiry,getWithExpiry} from "../Helper";
import Sidebar from "./Sidebar";
// import ContextUserInfo from "../context/UserInformation";

const Layout = () => {
  const navbarHeight = "60px";

  // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
  const userInfo = getWithExpiry('user');
  const token = localStorage.getItem("token");

  return (
    <Box sx={{ width: "100vw" }} className="mahan">
      {userInfo && (
        <Box
          sx={{
            width: "100%",
            height: navbarHeight,
            background: "#0a1928",
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <Sidebar />
        </Box>
      )}
      <Box sx={{ pt: navbarHeight, background: "#0a1928" }}>
        {userInfo ? (
          <Outlet />
        ) : token ? (
          <Navigate to="/loading" />
        ) : (
          <Navigate to="/auth/login" replace />
        )}
      </Box>
    </Box>
  );
};

export default Layout;
