import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay} from "swiper";
import "swiper/css";
import {Box, Typography} from "@mui/material";
import {AiFillStar} from "react-icons/ai";
import {styled} from "@mui/material/styles";

SwiperCore.use([Autoplay]);

const SubSliders = ({categoryData}) => {
    const navigate = useNavigate();
    const {medias, title} = categoryData;
    const MarqueeText = styled(Typography)({
        animation: "marquee 5s cubic-bezier(1,1,1,1) infinite",
        whiteSpace: "nowrap",
        width: "auto",
        "@keyframes marquee": {
            "0%": {
                transform: "translateX(100%)",
            },
            "100%": {
                transform: "translateX(-150%)",
            },
        },
    });

    const handleMovieNavigate = (id) => {
        navigate(`/movie/${id}`);
    };

    return (
        <Box sx={{display: "flex", flexDirection: "column", mb: 2}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mx: 2,
                }}
            >
                <Typography sx={{fontFamily: "YekanBakhFat", fontSize: 20}}>
                    {title}
                </Typography>
                {/* <Typography
          sx={{ mb: 1, fontSize: 12, opacity: 0.5, cursor: "pointer" }}
          onClick={() => console.log("show the complete list")}
        >
          مشاهده لیست
        </Typography> */}
            </Box>

            {/* card part */}
            <Box sx={{px: 1}}>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3.5}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    loop
                    centeredSlides={false}
                >
                    {medias &&
                        medias.map(
                            ({
                                 id,
                                 persian_name,
                                 english_name,
                                 image,
                                 imdb_score,
                                 likes,
                                 badges,
                                 is_dubbed,
                                 is_family_friendly,
                                 status,
                                 type,
                             }) => (
                                <SwiperSlide key={id} onClick={() => handleMovieNavigate(id)}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            position: "relative",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {/* slider image */}
                                        <img
                                            loading="lazy"
                                            src={image}
                                            style={{
                                                height: "130px",
                                                width: "100%",
                                                objectFit: "cover",
                                                marginTop: "5px",
                                                borderRadius: "10px",
                                                position: "relative",
                                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
                                                filter: "brightness(60%)",
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                position: "absolute",
                                                top: "10px",
                                                right: "5px",
                                                mx: 0.2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    ml: 0.2,
                                                    fontSize: 13,
                                                    color: "secondary",
                                                    fontWeight: 900,
                                                }}
                                            >
                                                {imdb_score}
                                            </Typography>
                                            <AiFillStar style={{color: "#ffeb3b", fontSize: 18}}/>
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                bottom: 0,
                                                right: 0,
                                                left: 0,
                                                display: "flex",
                                                py: 0.3,
                                                // borderRadius: "0 0 16px 16px",
                                                backgroundImage:
                                                    "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1))",
                                                // backdropFilter: "blur(7px)",1
                                                // WebkitBackdropFilter: "blur(7px)",
                                            }}
                                        >
                                            {/* slider title  */}
                                            {english_name.length > 10 ? (
                                                <MarqueeText
                                                    sx={{
                                                        width: "100%",
                                                        fontSize: 10,
                                                        color: "primary",
                                                    }}
                                                >
                                                    {english_name}
                                                </MarqueeText>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        fontSize: 10,
                                                        color: "primary",
                                                        marginLeft: "auto",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {english_name}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            )
                        )}
                </Swiper>
            </Box>
        </Box>
    );
};

export default SubSliders;
