import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";

const MoviePlayer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [movieLink, setMovieLink] = useState("");

  const playerRef = useRef(null);

  const toggleFullScreen = () => {
    const videoElement = playerRef.current.getInternalPlayer();

    videoElement.requestFullscreen();
  };

  useEffect(() => {
    location?.state?.movieLink
      ? setMovieLink(location.state.movieLink)
      : navigate(-1);
  }, []);

  useEffect(() => {
    function handleFullScreenChange() {
      if (!document.fullscreenElement) {
        navigate(-1);
      }
    }

    if (!document.fullscreenElement) {
      document.addEventListener("fullscreenchange", handleFullScreenChange);
    }

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {movieLink && (
        <ReactPlayer
          onReady={toggleFullScreen}
          ref={playerRef}
          url={movieLink}
          controls
          playing
          width={"100%"}
          pip
          style={{
            position: "fixed",
            top: "22%",
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        />
      )}
    </div>
  );
};

export default MoviePlayer;
