import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import { Box, Typography } from "@mui/material";

const MovieCast = ({ actor, director, writer }) => {
  const [cast, setCast] = useState("");

  useEffect(() => {
    let newCast = "";
    if (actor && director && writer) {
      newCast = writer.concat(actor);
      newCast = director.concat(newCast);
      setCast(newCast);
      return;
    } else if (actor && director) {
      newCast = director.concat(actor);
      setCast(newCast);
      return;
    } else if (actor && writer) {
      newCast = writer.concat(actor);
      setCast(newCast);
      return;
    } else if (director && writer) {
      newCast = director.concat(writer);
      setCast(newCast);
      return;
    } else if (actor) {
      setCast(actor);
      return;
    } else if (director) {
      setCast(director);
      return;
    } else if (writer) {
      setCast(writer);
      return;
    }

    const cast = director.concat(actor);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
      <Box>
        <Swiper spaceBetween={0} slidesPerView={1.5} centeredSlides={false}>
          {cast &&
            cast.map(({ id, name, roll, details }) => {
              const { title, image } = JSON.parse(details);
              return (
                <SwiperSlide key={id}>
                  <Box
                    sx={{
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      ml: 2,
                      borderRadius: "10px",
                      background: "#444",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={
                        image === "null" || !image
                          ? "/images/userDefault.png"
                          : image
                      }
                      alt={name}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "33%",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        ml: 1.5,
                      }}
                    >
                      <Typography sx={{ fontSize: 13, color: "#fff" }}>
                        {title}
                      </Typography>
                      {roll && (
                        <Typography
                          sx={{ fontSize: 12, color: "#fefe", mt: 1 }}
                        >
                          {roll}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default MovieCast;
