import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import MoveMainPart from "./MovieMainPart/MoveMainPart";
import MoveInfoPart from "./MovieInfoPart/MoveInfoPart";
import { getMovie } from "../../Services/Connection/ApiEndpoints/movies";
import MovieNavbar from "../../Layouts/MovieNavbar";
import ContextUserInfo from "../../context/UserInformation";
import MovieLoading from "../Loading/MovieLoading";
import { getWithExpiry } from "../../Helper";

const Movie = () => {
  // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
  const userInfo = getWithExpiry("user");
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [movieData, setMovieData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    !userInfo && navigate("/loading");

    const fetchData = async () => {
      const { data } = await getMovie(id);

      if (data?.status === "success") {
        setMovieData(data);
        setIsLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  return isLoading ? (
    <MovieLoading />
  ) : (
    <Box sx={{ height: "100vh" }}>
      <Box
        sx={{
          height: "42vh",
          background: "#0a1928",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          zIndex: 10,
        }}
      >
        <MoveMainPart movieData={movieData} />
      </Box>
      <Box sx={{ pt: "40vh", background: "#0a1928" }}>
        <MoveInfoPart movieData={movieData} />
      </Box>
      <MovieNavbar movieData={movieData} />
    </Box>
  );
};

export default Movie;
