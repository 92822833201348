import React, { useState, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { likeMovie } from "../Services/Connection/ApiEndpoints/movies";
// import ContextUserInfo from "../context/UserInformation";
import { getWithExpiry, setWithExpiry } from "../Helper";

const navbarHeight = "60px";

const MovieNavbar = ({ movieData }) => {
  const navigate = useNavigate();
  // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
  const userInfo = getWithExpiry("user");
  const [liked, setLiked] = useState(false);

  const { id } = movieData?.media;

  useEffect(() => {
    if (userInfo?.likes && userInfo?.likes?.media) {
      userInfo?.likes?.media.forEach((movieId) => {
        movieId === id && setLiked(true);
      });
    }
  }, []);

  const handleLikeMovie = async () => {
    setLiked(!liked);
    try {
      const { data } = await likeMovie(id);

      if (data?.status === "success") {
        const newUserInfo = { ...userInfo };

        if (data?.is_liked) {
          if (newUserInfo?.likes?.media) {
            newUserInfo.likes.media.push(id);
            localStorage.removeItem("user");
            // setUserInfo(newUserInfo);
            setLiked(true);
          } else {
            const media = [];
            newUserInfo.likes = { media };
            localStorage.removeItem("user");
            setLiked(true);
          }
        } else {
          const newLikes = newUserInfo?.likes?.media.filter(
            (likedId) => likedId !== id
          );
          newUserInfo.likes.media = newLikes;
          localStorage.removeItem("user");
          setLiked(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: "100vw", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: navbarHeight,
          position: "fixed",
          top: 15,
          zIndex: 100,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => navigate("/")}
          sx={{
            background: "rgba( 11,25,40, 0.7 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 4,
            mx: 2,
          }}
        >
          <ArrowForwardIosIcon sx={{ color: "#fff" }} />
        </IconButton>
        <IconButton
          onClick={handleLikeMovie}
          sx={{
            background: "rgba( 11,25,40, 0.7 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 4,
            mx: 2,
          }}
        >
          {liked ? (
            <FavoriteIcon sx={{ color: "#fff" }} />
          ) : (
            <FavoriteBorderIcon sx={{ color: "#fff" }} />
          )}
        </IconButton>
      </Box>
      {/* <Box sx={{ background: "#0a1928" }}>
        <Outlet />
      </Box> */}
    </Box>
  );
};

export default MovieNavbar;
