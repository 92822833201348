import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, Typography} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
    getPlans,
    purchacePlan,
} from "../../../Services/Connection/ApiEndpoints/plans";
import PurchaceCard from "./PurchaceCard/PurchaceCard";
import {useNavigate} from "react-router-dom";

const navbarHeight = "60px";

const PlansPurchace = () => {
    const [plansList, setPlansList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const {data} = await getPlans();
            setPlansList(data?.plans);
        };
        fetchData();
    }, []);

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 10,
            px: 2
        }}>
            <Box
                sx={{
                    width: "100%",
                    height: navbarHeight,
                    position: "fixed",
                    top: 15,
                    zIndex: 100,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <IconButton
                    onClick={() => navigate(-1)}
                    sx={{
                        background: "rgba( 255, 255, 255, 0.25 )",
                        backdropFilter: "blur(3px)",
                        WebkitBackdropFilter: "blur(3px)",
                        borderRadius: 4,
                        mx: 2,
                    }}
                >
                    <ArrowForwardIosIcon sx={{color: "#fff"}}/>
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 3,
                }}
            >
                <img style={{width: "75px", height: "75px"}} src={"/images/hmvz.png"}/>
                <Typography
                    sx={{
                        color: "primary",
                        fontSize: 24,
                        fontFamily: "YekanBakhFat",
                    }}
                >
                    اشتراک ویژه هایلی موویز
                </Typography>
                <Typography sx={{color: "primary", fontSize: 14, textAlign: "center"}}>
                    با خرید اشتراک ویژه هایلی به 20 هزار فیلم و سریال بروز دسترسی داشته باشید و با بالاترین سرعت از
                    تماشای فیلم لذت ببرید.
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {plansList.map(
                    ({id, title, price, duration, discount, description}, index) => (
                        <PurchaceCard
                            key={id}
                            index={index}
                            id={id}
                            title={title}
                            price={price}
                            duration={duration}
                            discount={discount}
                            description={description}
                        />
                    )
                )}
            </Box>
        </Box>
    );
};

export default PlansPurchace;

// {plansList.map(
//   ({ id, title, price, duration, discount, description }) => (
//     <Box key={id} sx={{ pt: 2, px: 4 }}>
//       <Button onClick={() => handlePurchace(id)} sx={{ width: "100%" }}>
//         {price + " تومان "}
//       </Button>
//     </Box>
//   )
// )}
