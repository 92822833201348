import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import { loginReq } from "../../../Services/Connection/ApiEndpoints/authentication";
import { toast } from "react-toastify";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cellphone, setCellphone] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      const { cellphone } = location.state;
      setCellphone(cellphone);
    }
  }, []);

  const onSubmitHandler = async () => {
    setIsLoading(true);

    const { data } = await loginReq(cellphone);

    if (data.status === "success") {
      navigate("/auth/verifyCode", {
        state: { cellphone, isUser: data.is_registered },
      });
      setIsLoading(false);
    } else {
      toast.error(data.errors.cellphone[0]);
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          sx={{
            color: "primary",
            fontSize: 24,
            fontFamily: "YekanBakhFat",
          }}
        >
          خوش آمدید
        </Typography>
        <Typography sx={{ color: "primary", fontSize: 14 }}>
          لطفا اطلاعات ورود را وارد کنید
        </Typography>
      </Box>

      <TextField
        type={"tel"}
        label={"شماره همراه"}
        value={cellphone}
        onChange={(e) => setCellphone(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && onSubmitHandler()}
        sx={{
          mt: 2,
          ".MuiInputBase-input": { textAlign: "right", direction: "rtl" },
        }}
        fullWidth
        required
      />

      <Box>
        <LoadingButton
          loading={isLoading}
          loadingIndicator={<CircularProgress color="inherit" size={20} />}
          sx={{ marginTop: 5, width: "100%" }}
          onClick={onSubmitHandler}
          variant="contained"
          className="font-sans"
          disabled={cellphone.length < 1}
        >
          تایید و ادامه
        </LoadingButton>

        <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
          <hr
            style={{
              borderTop: "1px rounded #bbb",
              width: "100px",
              borderRadius: "5px",
            }}
          />
          <Typography sx={{ fontSize: 14 }}>یا</Typography>
          <hr
            style={{
              borderTop: "1px rounded #bbb",
              width: "100px",
              borderRadius: "5px",
            }}
          />
        </Box>

        <Button
          onClick={() => navigate("/auth/hmvzLogin")}
          variant="contained"
          className="font-sans"
          fullWidth
          sx={{ mt: 1 }}
        >
          ورود با حساب هایلی
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
