import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "IRANSans",
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          ".MuiFormLabel-root": {
            color: "#fff",
            fontFamily: "IRANSans !important",
          },
          ".MuiInputBase-input": {
            color: "#fff",
            direction: "ltr",
            textAlign: "left",
          },
          ".MuiInputBase-root": {
            borderRadius: "8px",
          },
          ".MuiOutlinedInput-root": {
            "& fieldset": {
              border: "2px solid #e7ecf4",
            },
          },
          ".MuiOutlinedInput-root:hover": {
            "& fieldset": {
              border: "2px solid #e7ecf4",
            },
          },
          ".Mui-error:hover": {
            "& fieldset": {
              border: "2px solid #d32f2f",
            },
          },
          ".MuiInputAdornment-outlined": {
            borderLeft: "2px solid #e7ecf4",
            paddingLeft: "12px",
            height: "60px",
            width: "14px",
          },
          ".MuiInputAdornmentStart-outlined": {
            borderLeft: "none",
            paddingLeft: "0",
            marginLeft: "0px",
            borderRight: "2px solid #e7ecf4",
            paddingRight: "12px",
            marginRight: "12px",
            height: "60px",
            width: "14px",
          },
          ".MuiInputAdornmentBigEnd-outlined": {
            padding: "0",
            margin: "0",
            border: "0",
            marginRight: "35px",
          },
          ".MuiInputAdornmentBig-outlined": {
            padding: "0",
            margin: "0",
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        subheader: {
          color: "primary",
        },
        avatar: {
          marginLeft: "16px",
          marginRight: 0,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: "#e7ecf4",
          color: "#444",
          "&:hover": {
            backgroundColor: "#e7ecf4",
          },
          "&:disabled": {
            backgroundColor: "#919191",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          ".MuiTab-root": {
            color: "#575757",
          },
          ".Mui-selected": {
            color: "primary",
          },
          ".MuiTabs-indicator": {
            display: "none",
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme(defaultTheme, {
  palette: {
    mode: "light",
    primary: {
      main: "#39245d",
    },
    secondary: {
      main: "#ffffff08",
    },
    background: {
      default: "#f7f7f7",
      paper: "#fff",
    },
    text: {
      primary: "#444",
      secondary: "#fff",
      light: "#9ea1a4",
    },
    success: {
      primary: "#169f27",
      secondary: "#ea580b",
    },

    gradientButton: {
      base: "linear-gradient(216deg, rgb(98 77 144) 0%, rgb(61 42 103) 100%)",
      selected:
        "linear-gradient(216deg, rgb(98 9 180) 0%, rgb(61 42 103) 100%)",
      hover: "linear-gradient(216deg, rgb(98 77 144) 0%, rgb(61 42 103) 100%)",
    },
  },
});

export const darkTheme = createTheme(defaultTheme, {
  palette: {
    mode: "dark",
    primary: {
      main: "#fefefe",
    },
    secondary: {
      main: "#444",
    },
    background: {
      default: "#0a1928",
      paper: "#fff",
    },
  },
});
