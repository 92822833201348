import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BsPlayFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { FaCrown } from "react-icons/fa";
import { Box, Typography, IconButton, Modal } from "@mui/material";
import { getMovieLink } from "../../../../Services/Connection/ApiEndpoints/movies";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  bottom: "0",
  width: "100%",
  overflowY: "scroll",
  background: "rgba( 11,25,40, 0.3 )",
  backdropFilter: "blur(3px)",
  WebkitBackdropFilter: "blur(3px)",
  borderRadius: 5,
};

const MovieDownload = ({ hasSeasons, files }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [movieLink, setMovieLink] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGetLink = async (id) => {
    try {
      const { data } = await getMovieLink(id);

      if (data?.status === "success") {
        setMovieLink(data?.url);
        window.location.href = data?.url;
      }
    } catch (error) {
      if (error?.response.status === 403) {
        navigate("/plans/purchace");

        toast.error(error?.response?.data?.errors);
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          border: "1px solid #fefe",
          borderRadius: "10px",
          p: 1.25,
        }}
      >
        <FiDownload style={{ fontSize: 20, color: "#fff" }} />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ px: 2, maxHeight: "85vh" }}>
            {/* inside of modal */}
            {!hasSeasons && (
              <Box sx={{ py: 2 }}>
                {files &&
                  files.map(({ id, quality, title, type, is_free }) => (
                    <Box
                      key={id}
                      onClick={() => handleGetLink(id)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        mt: 0.5,
                        px: 2,
                        py: 1,
                        position: "relative",
                        background:
                          type === "original"
                            ? "#ff5800"
                            : type === "dubbed"
                            ? "#2dba5e"
                            : type === "subtitle"
                            ? "#00b9d0"
                            : null,
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          pt: 0.5,
                          pr: 0.5,
                          fontSize: 12,
                        }}
                      >
                        {title}
                      </Typography>
                      <FaCrown
                        style={{
                          fontSize: 19,
                          color: "#ffd444",
                          marginRight: "5px",
                          paddingLeft: "2px",
                          visibility: is_free ? "hidden" : "visible",
                        }}
                      />
                      <FiDownload
                        style={{
                          fontSize: 20,
                          color: "#fefe",
                          marginRight: "5px",
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MovieDownload;
