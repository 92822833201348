import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SearchItem = ({ item, isResult }) => {
  const {
    id,
    persian_name,
    english_name,
    image,
    imdb_score,
    is_dubbed,
    is_family_friendly,
    likes,
    status,
    type,
  } = item;

  const navigate = useNavigate();

  const handleMovieNavigate = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #545c66",
        background: "#0b1928",
        // background: "#ebebeb",
        py: 1,
        px: 2,
      }}
      onClick={() => handleMovieNavigate(id)}
    >
      <img
        src={image}
        alt="تصویر فیلم"
        style={{
          width: "35px",
          height: "30px",
          objectFit: "cover",
          marginLeft: "10px",
          borderRadius: "7px",
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: 14, color: "primary.main" }}>
          {persian_name}
        </Typography>
        <Typography sx={{ fontSize: 14, color: "primary.main" }}>
          {english_name}
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchItem;
