import { Box } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";

const Plans = () => {
  return (
    <Box sx={{ height: "100vh", background: "#0a1928" }}>
        <Outlet />
    </Box>
  );
};

export default Plans;
