import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import PlanBanner from "./components/PlanBanner";
import TopSlider from "./components/TopSlider";
import SubSliders from "./components/SubSliders";
import { getMainPageMovies } from "../../Services/Connection/ApiEndpoints/movies";
import MainPageLoading from "../Loading/MainPageLoading";
// import ContextUserInfo from "../../context/UserInformation";
import { getUser } from "../../Services/Connection/ApiEndpoints/authentication";
import { setWithExpiry, getWithExpiry } from "../../Helper";

const Home = () => {
  const userInfo = getWithExpiry("user");

  const location = useLocation();
  const [topSliderData, setTopSliderData] = useState(
    getWithExpiry("discoverSlider") || null
  );
  const [categoriesData, setCategoriesData] = useState(
    getWithExpiry("discoverCategories") || null
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // if(!userInfo){
    //     setIsLoading(true);
    //     const fetchUserInfo = async () => {
    //         const {data} = await getUser();
    //         if (data) {
    //             setUserInfo(data);
    //         }
    //     };
    //     fetchUserInfo().catch(console.error);
    // }

    // if (location?.state) {
    //   const { toastStatus, toastMessage } = location?.state;
    //   setToastMS(toastMessage);
    //   setToastStatus(toastStatus);
    // }

    const token = localStorage.getItem("token");
    if (!topSliderData || !categoriesData) {
      setIsLoading(true);
      const fetchData = async () => {
        const { data } = await getMainPageMovies();

        if (data?.status === "success") {
          setTopSliderData(data?.slider);
          setCategoriesData(data?.categories);
          setWithExpiry("discoverSlider", data?.slider, 60);
          setWithExpiry("discoverCategories", data?.categories, 60);
        }
        setIsLoading(false);
      };
      fetchData().catch(console.error);
    }
  }, []);

  return (
    <Box sx={{ pt: 1, pb: 2, display: "flex", flexDirection: "column" }}>
      {userInfo.plan_tag === "basic" && <PlanBanner />}

      {isLoading ? (
        <MainPageLoading />
      ) : (
        <>
          {topSliderData && <TopSlider topSliderData={topSliderData} />}

          {categoriesData &&
            categoriesData
              .slice(0, 3)
              .map((category, i) => (
                <SubSliders key={i} categoryData={category} />
              ))}
        </>
      )}
    </Box>
  );
};

export default Home;

const topSliderFakeData = [
  {
    id: "1",
    title: "فیلم تست اول",
    image: "/images/movie1.jpg",
    badges: ["جدید", "اکشن"],
    rate: 7.8,
  },
  {
    id: "2",
    title: "فیلم تست دوم",
    image: "/images/movie2.jpg",
    badges: ["جدید"],
    rate: 7.8,
  },
  {
    id: "3",
    title: "فیلم تست اول",
    image: "/images/movie1.jpg",
    badges: ["جدید", "اکشن"],
    rate: 7.8,
  },
  {
    id: "4",
    title: "فیلم تست دوم",
    image: "/images/movie2.jpg",
    badges: ["جدید", "اکشن"],
    rate: 7.8,
  },
  {
    id: "5",
    title: "فیلم تست اول",
    image: "/images/movie1.jpg",
    badges: ["جدید"],
    rate: 7.8,
  },
  {
    id: "6",
    title: "فیلم تست دوم",
    image: "/images/movie2.jpg",
    badges: ["جدید", "اکشن"],
    rate: 7.8,
  },
];

const updatingSeriesFakeData = [
  {
    id: "1",
    title: "فیلم تست اول",
    image: "/images/movie1.jpg",
    badges: ["اکشن", "جدید"],
    rate: 7.8,
  },
  {
    id: "2",
    title: "فیلم تست دوم",
    image: "/images/movie2.jpg",
    badges: ["اکشن", "جدید"],
    rate: 7.8,
  },
  {
    id: "3",
    title: "فیلم تست اول",
    image: "/images/movie1.jpg",
    badges: ["اکشن", "جدید"],
    rate: 7.8,
  },
  {
    id: "4",
    title: "فیلم تست دوم",
    image: "/images/movie2.jpg",
    badges: ["اکشن", "جدید"],
    rate: 7.8,
  },
  {
    id: "5",
    title: "فیلم تست اول",
    image: "/images/movie1.jpg",
    badges: ["اکشن", "جدید"],
    rate: 7.8,
  },
  {
    id: "6",
    title: "فیلم تست دوم",
    image: "/images/movie2.jpg",
    badges: ["اکشن", "جدید"],
    rate: 7.8,
  },
];
