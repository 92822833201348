import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getWithExpiry, setWithExpiry } from "../../../Helper";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import OtpInputMahan from "./OtpInputMahan/OtpInputMahan";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import {
  loginHmvz,
  loginReq,
  verifyOtpCodeReq,
} from "../../../Services/Connection/ApiEndpoints/authentication";
import { verifyOtpCodeReqSignUp } from "../../../Services/Connection/ApiEndpoints/authentication";
import { toast } from "react-toastify";

export default function VerifyCode() {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState("00:10");
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [isUser, setIsUser] = useState(false);

  const [hmvz, setHmvz] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setHmvz(location?.state?.hmvz);
      setPassword(location?.state?.password);
      setCellphone(location?.state?.cellphone);
      setIsUser(location?.state?.isUser);

      if (!location?.state?.cellphone) navigate("/auth/login");
    } else {
      navigate("/");
    }
  }, []);

  const onSubmitHandler = async () => {
    setIsLoading(true);

    const { data } = isUser
      ? await verifyOtpCodeReq("email", hmvz, password, cellphone, otp)
      : await verifyOtpCodeReqSignUp(name, cellphone, email, otp);

    if (data.status === "success") {
      data?.token && localStorage.setItem("token", data.token);
      localStorage.removeItem("user");
      navigate("/");
      setIsLoading(false);
      setIsLoading(false);
    } else {
      const errors = Object.values(data?.errors);
      errors.forEach((error) => {
        if (typeof error === "string") {
          toast.error(error);
        } else {
          <Box sx={{ pt: 0.5 }}>{toast.error(error[0])}</Box>;
        }
      });
      setIsLoading(false);
    }
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleComplete = (finalValue) => {
    console.log("otp completed");
  };

  //opt request
  const handleResendCode = async () => {
    setTimer("02:00");

    if (hmvz) {
      const { data } = await loginHmvz("email", hmvz, password, cellphone);
    } else {
      const { data } = await loginReq(cellphone);
    }
  };

  useEffect(() => {
    otp.length === 5 && onSubmitHandler();
  }, [otp]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "60px",
          position: "fixed",
          top: 15,
          left: 0,
          right: 0,
          zIndex: 100,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            background: "rgba( 255, 255, 255, 0.25 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 4,
            mx: 2,
          }}
        >
          <ArrowForwardIosIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          sx={{
            color: "primary",
            fontSize: 24,
            fontFamily: "YekanBakhFat",
          }}
        >
          {isUser ? "کد تایید ورود" : "تکمیل ثبت نام"}
        </Typography>
        <Typography sx={{ color: "primary", fontSize: 14 }}>
          {isUser
            ? `پیامک حاوی کد تایید ارسال شده به شماره موبایل ${cellphone} را وارد کنید.`
            : `اطلاعات خود و پیامک حاوی کد تایید ارسال شده به شماره موبایل ${cellphone} را وارد کنید.`}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{ display: isUser ? "none" : "flex", flexDirection: "column" }}
        >
          <TextField
            type={"text"}
            label={"نام"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
            required
          />

          <TextField
            type={"email"}
            label={"ایمیل"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mt: 2,
              ".MuiInputBase-input": { textAlign: "right", direction: "rtl" },
            }}
            fullWidth
          />
        </Box>

        <OtpInputMahan
          value={otp}
          onChange={handleChange}
          onComplete={handleComplete}
          // error={isError}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        {timer === "00:00" && (
          <Button
            onClick={() => handleResendCode()}
            variant="text"
            sx={{
              fontSize: "14px",
              bgcolor: "inherit",
              padding: 0,
              color: "primary.main",
              "&:hover": {
                bgcolor: "inherit",
              },
            }}
          >
            ارسال مجدد کد تایید
          </Button>
        )}
        {timer !== "00:00" && (
          <CountdownTimer
            timer={timer}
            setTimer={setTimer}
            timerDurationLabel={"02:00"}
            timerDuration={120}
            fontSize={14}
          />
        )}
        {hmvz ? (
          <Button
            onClick={() =>
              navigate("/auth/hmvzLogin", {
                state: { cellphone, hmvz, password },
              })
            }
            variant="text"
            sx={{
              fontSize: "14px",
              bgcolor: "inherit",
              color: "primary.main",
              padding: 0,
              "&:hover": {
                bgcolor: "inherit",
              },
            }}
          >
            ویرایش شماره
          </Button>
        ) : (
          <Button
            onClick={() => navigate("/auth/login", { state: { cellphone } })}
            variant="text"
            sx={{
              fontSize: "14px",
              bgcolor: "inherit",
              color: "primary.main",
              padding: 0,
              "&:hover": {
                bgcolor: "inherit",
              },
            }}
          >
            ویرایش شماره
          </Button>
        )}
      </Box>
      <LoadingButton
        loading={isLoading}
        loadingIndicator={<CircularProgress color="inherit" size={20} />}
        sx={{ marginTop: 4 }}
        onClick={onSubmitHandler}
        variant="contained"
        disabled={otp.length !== 5}
      >
        تایید و ادامه
      </LoadingButton>
    </Box>
  );
}
