import http from "../httpService";
import { apiUrl } from "../config";

const apiEndpoint = apiUrl + "/media";

const moviesSearchApi = apiEndpoint + "/search";
const movieGetApi = apiEndpoint + "/";
const likeMovieApi = apiEndpoint + "/like/";
const saveMovieApi = apiEndpoint + "/bookmark/";

const movieLinkApi = apiEndpoint + "/file/";

//get main page movies for top and sub sliders
export function getMainPageMovies() {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  return http.get(apiEndpoint, { headers });
}

// search for movies
export function moviesSearch(query) {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  const params = { query };

  return http.get(moviesSearchApi, { params, headers });
}

//get main page movies for top and sub sliders
export function getMovie(id) {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  return http.get(movieGetApi + id, { headers });
}

//like movie
export function likeMovie(id) {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  return http.get(likeMovieApi + id, { headers });
}

//save movie
export function saveMovie(id) {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  return http.get(saveMovieApi + id, { headers });
}

//save movie
export function getMovieLink(id) {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  return http.get(movieLinkApi + id, { headers });
}
