import "./OtpInput.css";
import { FormHelperText } from "@mui/material";
import OtpInput from "react18-input-otp";

const OtpInputMahan = ({ value, onChange, onComplete, error = false }) => {
  return (
    <>
      <OtpInput
        value={value}
        onChange={onChange}
        onComplete={onComplete}
        numInputs={5}
        isInputNum
        shouldAutoFocus
        containerStyle={{ direction: "ltr", width: "100%" }}
        inputStyle={{
          width: "80%",
          marginTop: 20,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 10,
          borderWidth: 2,
          fontSize: 20,
          border: "solid",
          outline: "none",
          borderColor: error ? "#df484a" : "#e7ecf4",
        }}
        focusStyle={{ borderColor: "#9adada" }}
      />

      {error && (
        <FormHelperText sx={{ mt: 1.5 }} error={error !== undefined}>
          کد وارد شده اشتباه است
        </FormHelperText>
      )}
    </>
  );
};

export default OtpInputMahan;
