import React from "react";
import { Box, Skeleton } from "@mui/material";

const BookMardLoading = () => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={"40px"}
        height={"40px"}
        sx={{
          fontSize: "1rem",
          background: "#444",
          borderRadius: 4,
          m: 2,
        }}
      />
      <Box sx={{ display: "flex", px: 1 }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={"240px"}
          sx={{
            fontSize: "1rem",
            background: "#444",
            borderRadius: 4,
            display: "flex",
            flexGrow: 1,
            mx: 1,
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={"240px"}
          sx={{
            fontSize: "1rem",
            background: "#444",
            borderRadius: 4,
            display: "flex",
            flexGrow: 1,
            mx: 1,
          }}
        />
      </Box>
      <Box sx={{ display: "flex", px: 1, mt: 2 }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={"240px"}
          sx={{
            fontSize: "1rem",
            background: "#444",
            borderRadius: 4,
            display: "flex",
            flexGrow: 1,
            mx: 1,
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={"240px"}
          sx={{
            fontSize: "1rem",
            background: "#444",
            borderRadius: 4,
            display: "flex",
            flexGrow: 1,
            mx: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default BookMardLoading;
