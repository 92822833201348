import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ContextUserInfo from "./context/UserInformation";
import { getUser } from "./Services/Connection/ApiEndpoints/authentication";

import Mahan from "./pages/Mahan/Mahan";
import Layout from "./Layouts/Layout";
import MovieNavbar from "./Layouts/MovieNavbar";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Movie from "./pages/Movie/Movie";
import AuthPage from "./pages/Auth/Auth.page";
import Login from "./pages/Auth/Components/Login";
import VerifyCode from "./pages/Auth/Components/VerifyCode";
import Loading from "./pages/Loading/Loading";
import Plans from "./pages/Plans/Plans";
import PlansPurchace from "./pages/Plans/Purchace/PlansPurchace";
import PlansResults from "./pages/Plans/Results/PlansResults";
import SavedMovies from "./pages/SavedMovies/SavedMovies";
import MoviePlayer from "./pages/Movie/MovieInfoPart/components/MoviePlayer";
import HmvzLogin from "./pages/Auth/Components/HmvzLogin";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const ProjectRoutes = () => {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  axios.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500;

    if (expectedError) {
      switch (error.response.status) {
        case 403:
          navigate('/plans/purchace');
          break;
        default:
          toast.error("خطای غیرمنتظره رخ داده است.");
          break;
      }
    }
    return Promise.reject(error);
  });
  return (
    <ContextUserInfo.Provider value={{ userInfo, setUserInfo }}>
      <Routes>
        <Route path="loading" element={<Loading />} />

        <Route path="/auth" element={<AuthPage />}>
          <Route path="login" element={<Login />} />
          <Route path="hmvzLogin" element={<HmvzLogin />} />
          <Route path="verifyCode" element={<VerifyCode />} />
        </Route>

        <Route path="/movie/:id" element={<Movie />} />
        <Route path="/moviePlayer/:id" element={<MoviePlayer />} />

        <Route path="/bookmarks" element={<SavedMovies />} />

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/plans" element={<Plans />}>
          <Route path="purchace" element={<PlansPurchace />} />
          <Route path="results" element={<PlansResults />} />
        </Route>
      </Routes>
    </ContextUserInfo.Provider>
  );
};

export default ProjectRoutes;
