import React from "react";
import { Box, Typography } from "@mui/material";
import { AiFillStar } from "react-icons/ai";

const MovieTimingInfo = ({ imdbScore, releaseYear, duration }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: 12, color: "#fefe", fontWeight: 900 }}>
          {imdbScore}
        </Typography>
        <AiFillStar
          style={{ color: "#ffd444", fontSize: 16, strokeWidth: "30" }}
        />
      </Box>
      {duration && (
        <Typography
          sx={{ fontSize: 12, color: "#fefe", fontWeight: 900, mx: 1.5 }}
        >
          • <strong style={{ paddingInline: 9 }}>{duration}</strong> •
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: 12,
          color: "#fefe",
          fontWeight: 900,
          ml: !duration && 4,
        }}
      >
        {releaseYear}
      </Typography>
    </Box>
  );
};

export default MovieTimingInfo;
