import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { BsPlayFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { FaCrown } from "react-icons/fa";
import { Box, Typography, IconButton, Modal } from "@mui/material";
import { getMovieLink } from "../../../../Services/Connection/ApiEndpoints/movies";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  bottom: "0",
  width: "100%",
  overflowY: "scroll",
  background: "rgba( 11,25,40, 0.3 )",
  backdropFilter: "blur(3px)",
  WebkitBackdropFilter: "blur(3px)",
  borderRadius: 5,
  border: "none",
  outline: "none",
};

const SiresWatchModal = ({ open, currentEpisode, onClose, setEpisodeLink }) => {
  const navigate = useNavigate();
  const isAndroid = window.navigator.userAgent.match(/Android/i);

  const handlePlay = async (id, title) => {
    try {
      const { data } = await getMovieLink(id);

      if (data?.status === "success") {
        setEpisodeLink(data?.url);
        navigate(`/moviePlayer/${id}`, { state: { movieLink: data?.url } });
        //
        // if(isAndroid){
        //   // const intent = `intent:${data?.url}#Intent;type=video/*;end;`;
        //   // window.location.href = intent;
        //     window.open(data?.url)
        // }else{
        //   setEpisodeLink(data?.url);
        //   navigate(`/moviePlayer/${id}`, { state: { movieLink: data?.url } });
        // }
        // // navigate(`/moviePlayer/${id}`, { state: { movieLink: data?.url } });
      }
    } catch (error) {
      if (error?.response.status === 403) {
        navigate("/plans/purchace");

        toast.error(error?.response?.data?.errors);
      }
    }
  };

  const handleDownload = async (id) => {
    try {
      const { data } = await getMovieLink(id);

      if (data?.status === "success") {
        setEpisodeLink(data?.url);
        window.location.href = data?.url;
      }
    } catch (error) {
      if (error?.response.status === 403) {
        navigate("/plans/purchace");

        toast.error(error?.response?.data?.errors);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ px: 2, maxHeight: "85vh" }}>
          {/* inside of modal */}

          <Box sx={{ py: 2 }}>
            {currentEpisode?.files &&
              currentEpisode.files.map(
                ({ id, quality, title, type, is_free }) => (
                  <Box
                    key={id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "10px",
                      mt: 0.5,
                      px: 2,
                      py: 1,
                      position: "relative",
                      background:
                        type === "original"
                          ? "#ff5800"
                          : type === "dubbed"
                          ? "#2dba5e"
                          : type === "subtitle"
                          ? "#00b9d0"
                          : null,
                    }}
                  >
                    <Typography
                      onClick={() => handlePlay(id, title)}
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        pt: 0.5,
                        pr: 0.5,
                        fontSize: 12,
                      }}
                    >
                      {title}
                    </Typography>
                    <FaCrown
                      style={{
                        fontSize: 19,
                        color: "#ffd444",
                        marginRight: "5px",
                        paddingLeft: "2px",
                        visibility: is_free ? "hidden" : "visible",
                      }}
                    />
                    <FiDownload
                      onClick={() => handleDownload(id)}
                      style={{
                        fontSize: 20,
                        color: "#fefe",
                        marginRight: "5px",
                      }}
                    />
                  </Box>
                )
              )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SiresWatchModal;
