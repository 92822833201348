import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    IconButton,
    Button,
    Typography,
    CircularProgress,
} from "@mui/material";

import {purchacePlan} from "../../../../Services/Connection/ApiEndpoints/plans";
import {LoadingButton} from "@mui/lab";

const PurchaceCard = ({
                          index,
                          id,
                          title,
                          duration,
                          discount,
                          price,
                          description,
                      }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [bgColor, setBgColor] = useState(getRandomColor());

    function getRandomColor() {
        const colors = ["linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", "linear-gradient(45deg, #9C27B0 30%, #E040FB 90%)", "linear-gradient(45deg, #673AB7 30%, #9C27B0 90%)", "linear-gradient(45deg, #3F51B5 30%, #2196F3 90%)", "linear-gradient(45deg, #00BCD4 30%, #4CAF50 90%)", "linear-gradient(45deg, #FFEB3B 30%, #FF9800 90%)", "linear-gradient(45deg, #795548 30%, #607D8B 90%)", "linear-gradient(45deg, #FF5722 30%, #FFEB3B 90%)",];
        return colors[index];
    }

    const handlePurchace = async (planId) => {
        setIsLoading(true);
        try {
            const {data} = await purchacePlan(planId);

            if (data?.status === "success") window.location.href = data?.payUrl;
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                borderColor: "primary.main",
                color: "primary.main",
                borderRadius: "20px",
                overflow: "hidden",
                cursor: "pointer",
                mb: 2,
                mx: 1,
                px: 2,
                py: 2,
                position: "relative",
                backgroundImage: bgColor,
                backgroundSize: "cover",
                transition: "background-image 0.3s ease-in-out"
            }}
            onClick={() => !isLoading && handlePurchace(id)}
        >
            {isLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1,
                    }}
                >
                    <CircularProgress/>
                </Box>
            )}
            <Box
                sx={{
                    width: "100%",
                    visibility: isLoading ? "hidden" : "visible",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    pr: 2,
                }}
            >
                <Typography sx={{fontSize: 20, fontWeight: "bold", mb: 1}}>{title}</Typography>
                <Typography sx={{fontSize: 14, mb: 1}}>
                    {(price / 1000) + " هزارتومان "}
                </Typography>
            </Box>
        </Box>
    );
};

export default PurchaceCard;
