import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SearchItem from "./SearchItem/SearchItem";
import { moviesSearch } from "../../Services/Connection/ApiEndpoints/movies";

const Search = () => {
  const [search, setSearch] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    if (search && search.length > 2) {
      setIsSearchLoading(true);

      const timeoutId = setTimeout(async () => {
        const { data } = await moviesSearch(search);

        if (data?.status === "success") {
          setSearchData(data?.medias);
        }

        setIsSearchLoading(false);

        //move loading false to the next of request
        setIsSearchLoading(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    } else {
      setSearchData("");
    }
  }, [search]);

  const handleSearch = (value) => {
    setSearch(value);
    setIsSearchLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "16px",
        background: "rgba( 255, 255, 255, 0.25 )",
        overflow: "hidden",
        mr: 2,
      }}
    >
      <Box sx={{ px: 1.5, pt: 1, pb: 0.8 }}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <input
            type="text"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "16px",
              background: "none",
              backdropFilter: "blur(3px)",
              WebkitBackdropFilter: "blur(3px)",
              fontFamily: "IRANSans",
              fontSize: 14,
              color: "#fff",
              outline: "none",
              border: 0,
              marginLeft: "10px",
            }}
          />
          {isSearchLoading ? (
            <CircularProgress size={20} />
          ) : (
            <SearchIcon sx={{ color: "#fff" }} />
          )}
        </Box>
      </Box>

      {searchData && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "230px",
            overflow: "scroll",
          }}
        >
          {searchData &&
            searchData.map((item) => <SearchItem item={item} key={item.id} />)}
        </Box>
      )}
    </Box>
  );
};

export default Search;
