import { Box } from "@mui/material";
import React from "react";

import MovieBanner from "./components/MovieBanner";
import MovieTimingInfo from "./components/MovieTimingInfo";
import MovieBadges from "./components/MovieBadges";
import MovieStream from "./components/MovieStream";
import ContextUserInfo from "../../../context/UserInformation";

const MoveMainPart = ({ movieData }) => {
  const {
    persian_name,
    image,
    imdb_score,
    duration,
    release_year,
    badges,
    hasSeasons,
    files,
  } = movieData?.media;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      {/* movie picture and title */}
      <MovieBanner
        hasSeasons={movieData?.hasSeasons}
        files={movieData?.files}
        image={image}
        title={persian_name}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          mt: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* rating and timing information */}
          <MovieTimingInfo
            imdbScore={imdb_score}
            releaseYear={release_year}
            duration={duration}
          />
          {/* categories of movie */}
          <MovieBadges badges={JSON.parse(badges)} />
        </Box>
        {/* stream part of movie */}
        <MovieStream movieData={movieData} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: -15,
          width: "100%",
          height: "5px",
          background: "#0a1928",
          mt: 1,
          filter: "blur(2px)",
        }}
      />
    </Box>
  );
};

export default MoveMainPart;
