import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LoadingButton } from "@mui/lab";
import { loginHmvz } from "../../../Services/Connection/ApiEndpoints/authentication";
import { toast } from "react-toastify";

const HmvzLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [hmvz, setHmvz] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (location?.state) {
      setCellphone(location?.state?.cellphone);
      setHmvz(location?.state?.hmvz);
      setPassword(location?.state?.password);
    }
  }, []);

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const { data } = await loginHmvz("email", hmvz, password, cellphone);

      if (data.status === "success") {
        navigate("/auth/verifyCode", {
          state: { hmvz, password, cellphone, isUser: data.is_registered },
        });
      } else {
        const errors = Object.values(data?.errors);
        errors.forEach((error) => {
          if (typeof error === "string") {
            toast.error(error);
          } else {
            <Box sx={{ pt: 0.5 }}>{toast.error(error[0])}</Box>;
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error?.errors?.general);
    }
  };

  return (
    <Box>
      <IconButton
        onClick={() => navigate(-1)}
        sx={{
          position: "fixed",
          top: 20,
          left: 4,
          background: "rgba( 255, 255, 255, 0.25 )",
          backdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
          borderRadius: 4,
          borderRadius: 4,
          mx: 2,
        }}
      >
        <ArrowForwardIosIcon sx={{ color: "#fff" }} />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          sx={{
            color: "primary",
            fontSize: 24,
            fontFamily: "YekanBakhFat",
          }}
        >
          خوش آمدید
        </Typography>
        <Typography sx={{ color: "primary", fontSize: 14 }}>
          لطفا اطلاعات حساب هایلی خود را وارد کنید
        </Typography>
      </Box>

      <TextField
        label={"حساب کاربری یا ایمیل"}
        value={hmvz}
        onChange={(e) => setHmvz(e.target.value)}
        sx={{
          mt: 2,
          ".MuiInputBase-input": { textAlign: "right", direction: "rtl" },
        }}
        fullWidth
        required
      />

      <TextField
        type={"tel"}
        label={"شماره همراه"}
        value={cellphone}
        onChange={(e) => setCellphone(e.target.value)}
        sx={{
          mt: 2,
          ".MuiInputBase-input": { textAlign: "right", direction: "rtl" },
        }}
        fullWidth
        required
      />

      <TextField
        type={"password"}
        label={"رمزعبور"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{
          mt: 2,
          ".MuiInputBase-input": { textAlign: "right", direction: "rtl" },
        }}
        fullWidth
        required
      />

      <Box>
        <LoadingButton
          loading={isLoading}
          loadingIndicator={<CircularProgress color="inherit" size={20} />}
          sx={{ marginTop: 5, width: "100%" }}
          onClick={onSubmitHandler}
          variant="contained"
          className="font-sans"
          disabled={
            hmvz.length < 1 || password.length < 1 || cellphone.length < 1
          }
        >
          تایید و ادامه
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default HmvzLogin;
