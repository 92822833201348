import React, { useContext } from "react";
import { Box, Skeleton, Stack } from "@mui/material";
import ContextUserInfo from "../../context/UserInformation";
import {getWithExpiry} from "../../Helper";

const MainPageLoading = () => {
  // const { userInfo } = useContext(ContextUserInfo);
    const userInfo = getWithExpiry('user');
  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        {userInfo.plan_tag === "basic" && (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"200px"}
            sx={{
              fontSize: "1rem",
              background: "#444",
              borderRadius: "16px",
            }}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"200px"}
            sx={{
              fontSize: "1rem",
              background: "#444",
              borderRadius: "16px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={"20%"}
            height={"10px"}
            sx={{
              fontSize: "1rem",
              background: "#444",
              borderRadius: "16px",
              mt: 1,
              mb: 3,
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton
            variant="rectangular"
            width={"30%"}
            height={"20px"}
            sx={{
              fontSize: "1rem",
              background: "#444",
              borderRadius: "16px",
              mb: 2,
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Skeleton
              variant="rectangular"
              width={"48%"}
              height={"200px"}
              sx={{
                fontSize: "1rem",
                background: "#444",
                borderRadius: "16px",
              }}
            />
            <Skeleton
              variant="rectangular"
              width={"48%"}
              height={"200px"}
              sx={{
                fontSize: "1rem",
                background: "#444",
                borderRadius: "16px",
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default MainPageLoading;
