import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";

const MovieDescription = ({ description }) => {
  const [isCollapse, setIsCollapse] = useState(true);

  return (
    description && (
      <Box sx={{ px: 2, my: 3 }}>
        <Typography
          sx={{
            color: "primary",
            fontSize: 13,
            lineHeight: 1.8,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isCollapse ? 3 : null,
            transition: "all 3s linear",
          }}
        >
          {description}
        </Typography>
        <Button
          onClick={() => setIsCollapse(!isCollapse)}
          sx={{
            fontSize: 12,
            py: 0,
            m: 0,
            mt: 0.5,
          }}
        >
          {isCollapse ? "نمایش کامل متن" : "نمایش خلاصه متن"}
        </Button>
      </Box>
    )
  );
};

export default MovieDescription;
