import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { AiFillStar } from "react-icons/ai";

const EachSaveMovie = ({ id, image, persian_name, imdb_score, badges }) => {
  const navigate = useNavigate();

  const handleMovieNavigate = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <Box
      onClick={() => handleMovieNavigate(id)}
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* slider image */}
      <img
        src={image}
        style={{
          height: "240px",
          width: "100%",
          objectFit: "cover",
          marginTop: "5px",
          borderRadius: "30px",
          position: "relative",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
          borderRadius: "0 0 16px 16px",
          background: "rgba( 255, 255, 255, 0.25 )",
          backdropFilter: "blur(7px)",
          WebkitBackdropFilter: "blur(7px)",
          px: 1.5,
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* slider title  */}
          <Typography
            sx={{
              maxWidth: "100px",
              fontSize: 14,
              color: "primary",
            }}
          >
            {persian_name}
          </Typography>
          {/* slider rating */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mx: 0.2,
            }}
          >
            <Typography
              sx={{
                ml: 0.2,
                fontSize: 13,
                color: "secondary",
                fontWeight: 900,
              }}
            >
              {imdb_score}
            </Typography>
            <AiFillStar style={{ color: "#ffeb3b", fontSize: 18 }} />
          </Box>
        </Box>

        {/* slider badges */}
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
          {badges &&
            JSON.parse(badges).map((badge, i) => (
              <Typography
                key={i}
                sx={{
                  fontSize: 10,
                  mr: 0.5,
                  px: 1.5,
                  py: 0.5,
                  borderRadius: "30px",
                  background: "#3f51b5",
                  color: "#fefefe",
                }}
              >
                {badge}
              </Typography>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EachSaveMovie;
