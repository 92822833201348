import { Box } from "@mui/material";
import React from "react";
import EpisodeSelect from "../MovieMainPart/components/EpisodeSelect";

import MovieCast from "./components/MovieCast";
import MovieDescription from "./components/MovieDescription";
import MovieLinks from "./components/MovieWatch";

const MoveInfoPart = ({ movieData }) => {
  const { persian_summery } = movieData?.media;
  const { actor, director } = movieData?.metas;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* series select and episodes */}
      {movieData?.hasSeasons && <EpisodeSelect files={movieData?.files} />}

      {/* movie cast */}
      <MovieCast actor={actor} director={director} />

      {/* movie description */}
      <MovieDescription description={persian_summery} />
    </Box>
  );
};

export default MoveInfoPart;
