import { createTheme } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

const muiRtl = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "IRANSans",
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          ".MuiFormLabel-root": {
            right: 0,
            transformOrigin: "top right",
            transform: "translate(-14px, 2px) scale(1)",

            "&.Mui-focused": {
              transform: "translate(-14px, -9px) scale(0.75) !important",
            },
          },
          ".MuiInputBase-input": {
            direction: "rtl",
            textAlign: "right",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          ".MuiButton-endIcon": {
            marginLeft: "-4px",
            marginRight: "8px",
          },
          ".MuiButton-startIcon": {
            marginRight: "-4px",
            marginLeft: "8px",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginRight: "72px",
          marginLeft: "0",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingRight: "16px",
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          left: "16px",
          right: "auto",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: "-11px",
          marginLeft: "16px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          MuiOutlinedInput: {},
        },
        endAdornment: {
          // right: 'auto !important',
          // left: 9,
        },
      },
    },
  },
});

export default muiRtl;

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}
