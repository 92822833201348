import http from "../httpService";
import { apiUrl } from "../config";

const getPlansApi = apiUrl + "/plans";
const purchacePlanApi = apiUrl + "/plans/";

//get plans list
export function getPlans() {
  const token = localStorage.getItem("token");
  const config = { headers: { Authorization: `Bearer ${token}` } };

  return http.get(getPlansApi, config);
}
//purchace plan
export function purchacePlan(id) {
  const token = localStorage.getItem("token");
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const params = { callback_url: "http://localhost:3000/plans/results" };
  return http.post(purchacePlanApi + id, params, config);
}
