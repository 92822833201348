import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { toast } from "react-toastify";

const PlansResults = () => {
  const [searchParams] = useSearchParams();
  const naviagte = useNavigate();

  useEffect(() => {
    const encoded = searchParams.get("data");
    const decodedResult = JSON.parse(window.atob(encoded));

    if (decodedResult?.status === "success") {
      //Remove User data here to update plan tag
      localStorage.removeItem("user");
      toast.success("ok!");
      naviagte("/");
    } else {
      toast.error("nope!");
      naviagte("/");
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: 16,
      }}
    >
      <CircularProgress />
      <Typography sx={{ mt: 3 }}>درحال انتقال...</Typography>
    </Box>
  );
};

export default PlansResults;
