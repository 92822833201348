import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Icons from "react-icons/fa";
import { getWithExpiry, setWithExpiry } from "../Helper";
import MenuIcon from "@mui/icons-material/Menu";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { FaCrown } from "react-icons/fa";

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
  Avatar,
  Typography,
  Button,
} from "@mui/material";

import Search from "./Search/Search";
import ContextUserInfo from "../context/UserInformation";

export default function Sidebar() {
  // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
  const userInfo = getWithExpiry("user");
  const { id, name, cellphone, plan_tag, bookmarks, likes } = userInfo;

  const navigate = useNavigate();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("discoverSlider");
    localStorage.removeItem("discoverCategories");
    navigate("/auth/login");
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: "100vh",
        background: "rgba( 5,26,48, 1)",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", px: 2, py: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50, bgcolor: "#fefe" }}
              alt="Remy Sharp"
            >
              {name.slice(0, 2)}
            </Avatar>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderBottom: "1px solid #fefe",
                pt: 1,
                pb: 4,
              }}
            >
              <Typography sx={{ fontSize: 14 }}>{name}</Typography>
              <Typography sx={{ fontSize: 14 }}>{cellphone}</Typography>
              <Typography sx={{ fontSize: 14 }}>{`اشتراک ${
                plan_tag === "basic" ? "عادی" : "ویژه"
              }`}</Typography>
            </Box>
          </Box>
          <List sx={{ height: "90%", py: "20px" }}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pt: 0 }}
                onClick={() => navigate("/bookmarks")}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  <BookmarkIcon sx={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText primary={"مورد علاقه های من"} />
              </ListItemButton>
            </ListItem>
            {plan_tag === "basic" && (
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ pt: 0 }}
                  onClick={() => navigate("/plans/purchace")}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                    <FaCrown
                      style={{
                        color: "#fff",
                        fontSize: 22,
                        marginRight: "2px",
                        marginBottom: "5px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"اشتراک ویژه"} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>

        <Button
          sx={{
            mx: 2,
            my: 1,
            background: "inherit",
            color: "primary.main",
            border: "1px solid #fefe",
          }}
          onClick={handleLogout}
        >
          خروج از حساب کاربری
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          top: 15,
          zIndex: 100,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          my: 1,
        }}
      >
        <IconButton
          onClick={toggleDrawer("left", true)}
          sx={{
            background: "rgba( 255, 255, 255, 0.25 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            borderRadius: 4,
            mx: 2,
          }}
        >
          <MenuIcon sx={{ color: "#fff" }} />
        </IconButton>

        <Search />
      </Box>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </>
  );
}
