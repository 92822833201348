import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import {Box, Container, Link, Typography} from "@mui/material";

export default function AuthPage() {
    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState();

    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{
                height: "100vh",
                width: "100vw",
                scrollBehavior: "smooth",
                background: "#0a1928",
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "350px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        px: 3,
                    }}
                >
                    <img style={{width: "75px", height: "75px"}} src={"/images/hmvz.png"}/>
                    <Outlet/>
                </Box>
            </Box>
        </Container>
    );
}

// <Box
// sx={{
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   // padding: 2,
//   background: "#FFF",
// }}
// >
// <Box
//   sx={{
//     maxWidth: "20rem",
//     height: "100%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     position: "relative",
//   }}
// >
//   <Outlet />
// </Box>
// </Box>
