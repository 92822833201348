import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Box, Typography } from "@mui/material";
import { AiFillStar } from "react-icons/ai";

import "swiper/css";
import "swiper/css/pagination";
import { styled } from "@mui/material/styles";

SwiperCore.use([Autoplay, Pagination]);

const TopSlider = ({ topSliderData }) => {
  const navigate = useNavigate();
  const handleMovieNavigate = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <Box sx={{ px: 1 }}>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        {topSliderData.map(
          ({
            id,
            perisan_name,
            english_name,
            image,
            imdb_score,
            likes,
            is_dubbed,
            is_family_friendly,
            status,
            type,
            badges,
          }) => (
            <SwiperSlide key={id} onClick={() => handleMovieNavigate(id)}>
              <Box
                sx={{
                  height: "230px",
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <img
                  src={image}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                    marginTop: "5px",
                    borderRadius: "10px",
                    position: "relative",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
                    filter: "brightness(60%)",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    top: "20px",
                    right: "10px",
                    mx: 0.2,
                  }}
                >
                  <Typography
                    sx={{
                      ml: 0.2,
                      fontSize: 13,
                      color: "secondary",
                      fontWeight: 900,
                    }}
                  >
                    {imdb_score}
                  </Typography>
                  <AiFillStar style={{ color: "#ffeb3b", fontSize: 18 }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "absolute",
                    bottom: 20,
                    right: 0,
                    left: 0,
                    borderRadius: "0 0 16px 16px",
                    backgroundImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1))",
                    // backdropFilter: "blur(7px)",
                    // WebkitBackdropFilter: "blur(7px)",
                    px: 1.5,
                    py: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "primary",
                    }}
                  >
                    {english_name}
                  </Typography>
                  {/* slider badges */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      flexWrap: "wrap",
                    }}
                  >
                    {badges &&
                      JSON.parse(badges).map((badge, i) => (
                        <Typography
                          key={i}
                          sx={{
                            fontSize: 10,
                            mr: 0.5,
                            px: 1.5,
                            py: 0.5,
                            my: 1,
                            borderRadius: "30px",
                            background: "#3f51b5",
                            color: "#fefefe",
                          }}
                        >
                          {badge}
                        </Typography>
                      ))}
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </Box>
  );
};

export default TopSlider;
