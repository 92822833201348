import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton, Stack } from "@mui/material";
import {getWithExpiry, setWithExpiry} from "../../Helper";
import ContextUserInfo from "../../context/UserInformation";

import { getUser } from "../../Services/Connection/ApiEndpoints/authentication";
import { replace } from "stylis";

const Loading = () => {
  // const { userInfo, setUserInfo } = useContext(ContextUserInfo);
    const userInfo = getWithExpiry('user');
  const navigate = useNavigate();

  useEffect(() => {
      if(!userInfo){
          const fetchData = async () => {
              const { data } = await getUser();

              if (data) {
                  setWithExpiry('user', data, 60);
                  // setUserInfo(data);
                  navigate(-1, replace);
              }
          };

          fetchData().catch(() => navigate('/auth/login'));
      }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        height: "100vh",
      }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton
            variant="rectangular"
            sx={{
              height: 40,
              width: 40,
              borderRadius: "16px",
              background: "#444",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "60%",
              display: "flex",
              flexGrow: 1,
              height: 40,
              borderRadius: "16px",
              background: "#444",
              mx: 1,
            }}
          />
        </Box>

        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"111px"}
          sx={{
            fontSize: "1rem",
            background: "#444",
            borderRadius: "16px",
          }}
        />

        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"200px"}
          sx={{
            fontSize: "1rem",
            background: "#444",
            borderRadius: "16px",
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton
            variant="rectangular"
            width={"35%"}
            height={"20px"}
            sx={{
              fontSize: "1rem",
              background: "#444",
              borderRadius: "16px",
              mb: 2,
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Skeleton
              variant="rectangular"
              width={"48%"}
              height={"200px"}
              sx={{
                fontSize: "1rem",
                background: "#444",
                borderRadius: "16px",
              }}
            />
            <Skeleton
              variant="rectangular"
              width={"48%"}
              height={"200px"}
              sx={{
                fontSize: "1rem",
                background: "#444",
                borderRadius: "16px",
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Loading;
